import { useLocalStorage } from '@BlackbirdHQ/ui-base';
import { GetStaticProps, NextPage } from 'next';
import { useCallback, useEffect, useState } from 'react';

import * as Constants from '@/constants';
import { defaultCardConf } from '@/views/lines/overview-components/menu';
import LineCardPage from '@/views/lines/overview-page';

const Lines: NextPage = () => {
  const [cardConf, setCardConf] = useLocalStorage(
    Constants.LOCAL_STORAGE_LINES_OVERVIEW_LAYOUT_CONFIG,
    defaultCardConf,
  );
  const [timeAtLoad, setTimeAtLoad] = useState(new Date());

  const changeUpdateInterval = useCallback(
    (interval) => {
      setCardConf({
        ...cardConf,
        refreshInterval: interval,
      });
    },
    [cardConf],
  );

  useEffect(() => {
    if (!cardConf.refreshInterval) {
      return;
    }

    const handle = setTimeout(() => {
      setTimeAtLoad(new Date());
    }, cardConf.refreshInterval);

    return () => clearTimeout(handle);
  }, [timeAtLoad, cardConf.refreshInterval]);

  return (
    <LineCardPage
      timeAtLoad={timeAtLoad}
      updateInterval={cardConf.refreshInterval}
      changeUpdateInterval={changeUpdateInterval}
    />
  );
};

export const getStaticProps: GetStaticProps = async () => ({
  props: {
    namespaces: ['line'],
  },
});

export default Lines;
