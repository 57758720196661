// eslint-disable-next-line max-classes-per-file
import { Theme } from '@mui/material/styles';
import * as Schema from 'generated/graphql/schema';
import { SeriesScatterOptions } from 'highcharts';
import { partition } from 'lodash';

import { SAMPLE_POINTS } from '@/constants';
import { getManualProcessSamples } from '@/helpers/manual-process';
import { getBatchAndStopsPlotBands, getOverridesPlotBands, getSerieColor } from '@/lib/highcharts/config-helpers';
import * as Types from '@/types';

interface Properties {
  data: Data;
  selectedTime?: Date;
  pendingControls?: Schema.BatchControl[];
  selectedControlId?: string | null;
  onClickStop?: (stop: Schema.Stop) => void;
  onOverrideClick?: <T>(input: T) => void;
  chartHeight: number;
  disableLabels?: boolean;
  theme: Theme;
  refetch?: (start: Date, end: Date, isZoomEvent?: boolean) => void;
  setSelectedTime?: (time: Date) => void;
}

interface Data {
  time: Array<{
    stops?: Schema.Stop[];
    timeRange?: Schema.TimeRange;
    batches?: Schema.Batch[];
    dataOverrides?: Schema.DataOverride[];
  }>;
}

const manualProcessConfig = ({
  data,
  selectedTime,
  refetch,
  pendingControls,
  selectedControlId,
  chartHeight,
  theme,
  disableLabels,
  onClickStop,
  onOverrideClick,
  setSelectedTime,
}: Properties) => {
  const from = data.time?.[0]?.timeRange?.from ? new Date(data.time?.[0]?.timeRange?.from) : new Date();
  const to = data.time?.[0]?.timeRange?.to ? new Date(data.time?.[0]?.timeRange?.to) : new Date();

  const stops = data.time?.[0]?.stops ?? [];
  const batches = data.time?.[0]?.batches ?? [];
  const dataOverrides = data.time?.[0]?.dataOverrides ?? [];

  const selectedTimeSeries: SeriesScatterOptions[] = !selectedTime
    ? []
    : [
        {
          animation: false,
          type: 'scatter',
          showInLegend: false,
          enableMouseTracking: false,
          data: [
            {
              x: selectedTime.getTime(),
              y: 0,
              color: 'purple',
              marker: {
                radius: 6,
              },
            },
          ],
          states: {
            inactive: {
              opacity: 1,
            },
          },
        },
      ];

  return {
    title: false,
    exporting: {
      enabled: false,
    },
    chart: {
      resetZoomButton: {
        theme: {
          display: 'none',
        },
      },
      zoomType: 'x',
      height: chartHeight + 'px',
      events: {
        click(e: Event & Types.HighchartsChart) {
          if (setSelectedTime && e?.xAxis?.[0]?.value) {
            setSelectedTime(new Date(e?.xAxis?.[0]?.value));
          }
        },
        selection(e: Event & Types.HighchartsChart) {
          if (refetch) {
            e.preventDefault();
            refetch(new Date(e.xAxis[0].min), new Date(e.xAxis[0].max), true);
          }
        },
      },
    },
    series: [
      ...getSerie(from, to, stops, dataOverrides, setSelectedTime),
      ...getPendingControlsSeries(pendingControls ?? [], selectedControlId ?? null),
      ...selectedTimeSeries,
    ],
    xAxis: {
      type: 'datetime',
      dateTimeLabelFormats: {
        month: '%e. %b',
        year: '%b',
      },
      plotBands: [
        ...getBatchAndStopsPlotBands(batches, [], { from, to }, theme),
        ...getClickableStopPlotBands(stops, theme, onClickStop, disableLabels),
        ...getOverridesPlotBands(dataOverrides, { from, to }, theme, onOverrideClick),
      ],
      minTickInterval: (30).seconds,
    },
    yAxis: {
      min: 0,
      max: 2,
      maxPadding: 0.05,
      endOnTick: false,
      labels: {
        enabled: false,
      },
      title: {
        text: undefined,
      },
    },
    credits: {
      enabled: false,
    },
    legend: {
      enabled: true,
    },
    tooltip: {
      enabled: true,
      pointFormat: '',
    },
    plotOptions: {
      series: {
        states: {
          hover: {
            enabled: false,
            animation: false,
          },
        },
      },
      area: {
        marker: {
          radius: 2,
          enabled: false,
        },
        lineWidth: 1,
        states: {
          hover: {
            lineWidth: 1,
          },
        },
        threshold: null,
      },
    },
  };
};

const getSerie = (
  from: Date,
  to: Date,
  stops: Schema.Stop[],
  dataOverrides: Schema.DataOverride[],
  onClick?: (date: Date) => void,
) => {
  const stopTimes = stops.length > 0 ? stops : dataOverrides.filter((override) => override.value === 0);
  const samples = getManualProcessSamples(from, to, stopTimes, SAMPLE_POINTS);
  const color = getSerieColor(Schema.SensorType.MANUAL_PROCESS, 0);
  const data = samples.map((s) => ({
    x: new Date(s.timeRange.from).getTime(),
    y: s.data.accValue === 0 ? 0 : 1,
  }));

  return [
    // serie 0, main data line / area graph
    {
      animation: false,
      type: 'area',
      id: 'obj.name',
      name: '',
      data,
      color: color.color,
      showInLegend: false,
      shared: false,
      zIndex: 5,
      events: {
        click(e: Event & Types.HighchartsChart) {
          if (onClick && e?.point?.x) {
            onClick(new Date(e?.point?.x));
          }
        },
      },
    },
  ];
};

const getClickableStopPlotBands = (
  stops: Schema.Stop[],
  theme: Theme,
  onClick?: (stop: Schema.Stop) => void,
  disableLabels?: boolean,
) => {
  return stops.map((stop) => {
    const causeType = stop.stopCause
      ? stop.stopCause.stopType
      : ('UNREGISTERED_PLOT_BAND' as keyof typeof theme.blackbird.stops);

    return {
      from: stop.timeRange.from ? new Date(stop.timeRange.from).getTime() : null,
      to: stop.timeRange.to ? new Date(stop.timeRange.to).getTime() : null,
      color: theme.blackbird.stops[causeType].color.main,
      id: stop.timeRange.from,
      label: !disableLabels && {
        useHTML: true,
        text: stop.stopCause ? stop.stopCause.name : undefined,
        style: {
          width: '100px',
          textOverflow: 'ellipsis',
        },
        rotation: 90,
        textAlign: 'left',
        verticalAlign: 'top',
      },
      zIndex: 6,
      events: {
        click: onClick ? () => onClick(stop) : undefined,
      },
    };
  });
};

const getPendingControlsSeries = (pendingControls: Schema.BatchControl[], selectedControlId: string | null) => {
  const [selectedControls, unselectedControls] = partition(
    pendingControls,
    (control) => control.batchControlId === selectedControlId,
  );

  return [
    {
      animation: false,
      type: 'scatter',
      id: 'unselected-pending-controls',
      name: '',
      showInLegend: false,
      data: unselectedControls.map((control) => [new Date(control.timeTriggered).getTime(), 0]),
      marker: {
        fillColor: '#FFC107', // ffb300
        symbol: 'triangle',
        radius: 5,
      },
      zIndex: 10,
    },
    {
      animation: false,
      type: 'scatter',
      id: 'selected-pending-controls',
      name: '',
      showInLegend: false,
      data: selectedControls.map((control) => [new Date(control.timeTriggered).getTime(), 0]),
      marker: {
        fillColor: '#ff8f00',
        radius: 8,
        symbol: 'triangle',
      },
      zIndex: 10,
    },
  ];
};

export default manualProcessConfig;
