import { Card, CardContent, Grid } from '@mui/material';
import React, { FC } from 'react';
import { makeStyles } from 'tss-react/mui';

import SearchBar, { SearchValue } from '@/components/bars/search-bar';
import Paginator from '@/components/paginator';

const useStyles = makeStyles()((theme) => ({
  root: {
    overflow: 'visible',
    marginBottom: theme.spacing(),
    '@media print': {
      display: 'none',
    },
  },
  content: {
    paddingBottom: theme.spacing(2),
  },
  divContainer: {
    display: 'flex',
    width: '100%',
    flexWrap: 'nowrap',
  },
  hideOnSMDown: {
    display: 'flex',
    [theme.breakpoints.down('md')]: {
      display: 'none',
    },
  },
  hideOnMDup: {
    display: 'flex',
    [theme.breakpoints.up('md')]: {
      display: 'none',
    },
  },
}));

interface Properties {
  search: SearchValue[];
  suggestions: SearchValue[];
  totalCount: number;
  page: number;
  pageSize: number;
  handlePageChange: (page: number) => void;
  handleSearchChange: (val: SearchValue[]) => void;
}

const OverviewTopBar: FC<Properties> = ({
  suggestions,
  search,
  children,
  totalCount,
  page,
  pageSize,
  handleSearchChange,
  handlePageChange,
}) => {
  const { classes } = useStyles();

  return (
    <Card elevation={1} className={classes.root}>
      <CardContent className={classes.content}>
        <div className={classes.divContainer}>
          <SearchBar suggestions={suggestions} value={search} handleChange={handleSearchChange} />
          <div className={classes.hideOnSMDown}>
            <React.Suspense fallback={<div />}>
              <Paginator
                totalPages={Math.ceil(totalCount / pageSize)}
                currentPage={page}
                onCurrentPageChange={handlePageChange}
                totalCount={totalCount}
                pageSize={pageSize}
              />
            </React.Suspense>
            {children}
          </div>
        </div>
        <div className={classes.hideOnMDup}>
          <Grid justifyContent="space-between" container>
            <Grid item>
              <React.Suspense fallback={<div />}>
                <Paginator
                  totalPages={Math.ceil(totalCount / pageSize)}
                  currentPage={page}
                  onCurrentPageChange={handlePageChange}
                  totalCount={totalCount}
                  pageSize={pageSize}
                />
              </React.Suspense>
            </Grid>
            <Grid item>{children}</Grid>
          </Grid>
        </div>
      </CardContent>
    </Card>
  );
};

export default OverviewTopBar;
