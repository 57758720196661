import * as Schema from 'generated/graphql/schema';
import React, { FC, useMemo } from 'react';

import OverviewTopBar from '@/components/bars/overview-top-bar';
import { SearchValue } from '@/components/bars/search-bar';
import ConfigurationMenu, { CardConfiguration, TimeInterval } from '@/views/lines/overview-components/menu';

interface Properties {
  search: SearchValue[];
  page: number;
  pageSize: number;
  filteredLines: Schema.LinesListSimpleQuery['lines'];
  cardConf: CardConfiguration;
  updateInterval: number | null;
  nextLiveUpdate: Date | null;
  handleSearchChange: (search: SearchValue[]) => void;
  handlePageChange: (page: number) => void;
  updateConf: (key: string, check: boolean) => void;
  updateIntervalConf: (interval: TimeInterval) => void;
  updateRefreshIntervalConf: (newInterval: number | null) => void;
}

const LinesTopBar: FC<Properties> = ({
  search,
  page,
  filteredLines,
  pageSize,
  cardConf,
  handlePageChange,
  handleSearchChange,
  updateConf,
  updateIntervalConf,
  updateRefreshIntervalConf,
  updateInterval,
  nextLiveUpdate,
}) => {
  const suggestions: SearchValue[] = useMemo(() => {
    return filteredLines
      .filter((line) => {
        if (!search.length) {
          return true;
        }

        if (!line?.name) {
          return false;
        }

        return search.some((searchTerm) =>
          [line.name.toLowerCase(), (line.description || '').toLowerCase()].some(
            (entry) => searchTerm?.value?.value && entry.includes(searchTerm.value.value),
          ),
        );
      })
      .map((line) => {
        const label = line.name! || line.description!;
        return {
          label,
          value: {
            type: 'string',
            value: label.toLowerCase(),
          },
        };
      });
  }, [filteredLines, search]);

  const totalCount = filteredLines.length;

  return (
    <OverviewTopBar
      search={search}
      suggestions={suggestions}
      page={page}
      pageSize={pageSize}
      totalCount={totalCount}
      handlePageChange={handlePageChange}
      handleSearchChange={handleSearchChange}
    >
      <ConfigurationMenu
        cardConf={cardConf}
        updateConf={updateConf}
        updateIntervalConf={updateIntervalConf}
        updateInterval={updateInterval}
        updateRefreshIntervalConf={updateRefreshIntervalConf}
        nextLiveUpdate={nextLiveUpdate}
      />
    </OverviewTopBar>
  );
};

export default LinesTopBar;
